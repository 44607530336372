.kiwi-messagelist-message--text {
  position: relative;
  padding: 4px 10px;
  margin: 0;
  text-align: left;
}
.kiwi-messagelist-message--text .kiwi-messagelist-time {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 10px;
  display: none;
  opacity: 0.8;
}
.kiwi-messagelist-message--text:hover .kiwi-messagelist-time {
  display: block;
  border-radius: 5px 0 0 5px;
}
.kiwi-messagelist-message--text .kiwi-messagelist-seperator {
  display: none;
}
.kiwi-messagelist-message--text .kiwi-messagelist-nick {
  display: inline;
  text-align: left;
  margin-right: 2px;
  padding: 0;
}
.kiwi-messagelist-message--text .kiwi-messagelist-nick:hover {
  max-width: none;
  width: auto;
}
.kiwi-messagelist-message--text .kiwi-messagelist-body {
  display: inline;
  padding: 0;
  white-space: pre-wrap;
}
.kiwi-messagelist-message--text .kiwi-messagelist-body a {
  word-break: break-all;
}
.kiwi-messagelist-message--text .kiwi-messagelist-message-privmsg:hover,
.kiwi-messagelist-message--text .kiwi-messagelist-message-action:hover,
.kiwi-messagelist-message--text .kiwi-messagelist-message-notice:hover {
  cursor: pointer;
}
.kiwi-messagelist-message--text.kiwi-messagelist-message-topic {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin: 10px 0;
}
.kiwi-messagelist-message--text.kiwi-messagelist-message-topic .kiwi-messagelist-body {
  padding-right: 0;
  max-width: 95%;
  margin-left: 20px;
}
.kiwi-messagelist-message--text.kiwi-messagelist-message--unread {
  opacity: 1;
}
.kiwi-messagelist-message--text .kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-item:last-of-type {
  margin-bottom: 5px;
}
.kiwi-messagelist-message--text.kiwi-messagelist-message-nick .kiwi-messagelist-nick {
  display: none;
}
@media screen and (max-width: 700px) {
  .kiwi-messagelist-message--text.kiwi-messagelist-message-traffic,
  .kiwi-messagelist-message--text.kiwi-messagelist-message-nick .kiwi-messagelist-body,
  .kiwi-messagelist-message--text .kiwi-messagelist-body {
    padding-right: 0;
  }
}
