

















































































































































































































































































































































































.kiwi-customserver {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
    text-align: center;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
}

.kiwi-customserver-start {
    font-size: 1.1em;
    cursor: pointer;
}

.kiwi-customserver-form {
    max-width: 300px;
    margin: 0 auto;
    max-height: 500px;
    overflow: hidden;
    border-radius: 0.5em;
    padding: 20px 1em;
}

.kiwi-customserver .u-input-text,
.kiwi-customserver .kiwi-customserver-have-password input {
    margin-bottom: 1.5em;
}

.kiwi-customserver .kiwi-customserver-have-password {
    margin-bottom: 20px;
}

.kiwi-customserver-have-password input:checked {
    margin-bottom: 0;
}

.kiwi-customserver-tls {
    cursor: pointer;
    color: #bfbfbf;
}

.kiwi-customserver-tls--enabled {
    color: green;
}

.kiwi-customserver-tls-lock {
    font-size: 1.2em;
}

.kiwi-customserver-tls-minus {
    color: red;
    font-size: 0.7em;
    top: 3px;
}

.kiwi-customserver-loader {
    margin-top: 1em;
    font-size: 2em;
}

.kiwi-customserver-channel {
    margin-top: 1em;
}

.kiwi-customserver-form .u-submit {
    width: 100%;
    padding: 0;
    letter-spacing: 1px;
    font-weight: 400;
    margin: 0 0 20px 0;
    transition: all 0.2s;
    border: none;
    font-size: 1.2em;
    line-height: 36px;
}

.kiwi-customserver-server-types {
    font-size: 0.9em;
    text-align: center;
}

.kiwi-customserver-server-types a {
    margin: 0 1em;
}

.kiwi-customserver h2 {
    margin-bottom: 1.5em;
}

.kiwi-customserver h2 i {
    font-size: 0.8em;
    margin-left: 1em;
}

.kiwi-customserver--connecting h2 {
    transition: margin-top 0.7s;
    margin-top: 100px;
}

.kiwi-customserver-error {
    text-align: center;
    margin: 1em 0;
    padding: 0.3em;
}

.kiwi-customserver-error span {
    display: block;
    font-style: italic;
}

.kiwi-connectingloader-enter-active,
.kiwi-connectingloader-leave-active {
    transition: max-height 0.5s;
}

.kiwi-connectingloader-enter,
.kiwi-connectingloader-leave-to {
    max-height: 0;
}

