.kiwi-channelbanlist-table {
  width: 100%;
  border-collapse: collapse;
  line-height: 20px;
  margin-top: 10px;
}
.kiwi-channelbanlist-table tr {
  border-bottom: 1px solid;
}
.kiwi-channelbanlist-table-mask {
  word-break: break-all;
}
.kiwi-channelbanlist-table-bannedat {
  min-width: 150px;
}
.kiwi-channelbanlist-table-actions {
  min-width: 50px;
  text-align: center;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
  z-index: 1;
}
