






















































.u-input-confirm {
    display: inline-block;
    padding: 10px;
}

.u-input-confirm > a {
    margin-right: 10px;
}

.u-input-confirm > a:last-of-type {
    margin-right: 0;
}
