.kiwi-notconnected {
  box-sizing: border-box;
  padding: 5px 20px;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.kiwi-notconnected.connecting {
  text-align: center;
}
.kiwi-notconnected-caption {
  display: inline-block;
  width: auto;
  cursor: default;
  line-height: 50px;
  font-size: 1.2em;
  font-weight: 900;
}
.kiwi-notconnected-caption i {
  font-weight: normal;
  font-size: 1.5em;
  position: relative;
  right: -3px;
  top: 3px;
}
.kiwi-notconnected-captcha {
  display: inline-block;
  margin-left: 3em;
}
.kiwi-notconnected-buttons {
  float: right;
  width: auto;
  text-align: center;
}
.kiwi-notconnected-button {
  width: auto;
  display: inline-block;
  padding: 5px 10px;
  border-radius: 7px;
  cursor: pointer;
  border: 2px solid;
  margin: 6px 0 0 10px;
  height: 37px;
  overflow: hidden;
  box-sizing: border-box;
  transition: opacity 0.3s, color 0.3s, background-color 0.3s;
}
.kiwi-notconnected-button:hover[disabled] {
  cursor: not-allowed;
  opacity: 0.65;
}
.kiwi-notconnected-button:hover {
  opacity: 1;
  transition: all 0.2s;
}
.kiwi-notconnected-button i {
  float: left;
  font-size: 1.6em;
  line-height: 24px;
  margin-right: 5px;
}
.kiwi-notconnected-button-settings {
  opacity: 0.8;
  border: none;
}
.kiwi-notconnected-button-settings i {
  margin-right: 0;
}
@media screen and (max-width: 1024px) {
  .kiwi-notconnected-caption {
    font-size: 1em;
    width: 100%;
    text-align: center;
  }
  .kiwi-notconnected-buttons {
    width: 100%;
  }
  .kiwi-notconnected-button {
    margin: 0;
    font-size: 1em;
    padding: 0 10px;
    line-height: 33px;
    width: auto;
    box-sizing: border-box;
  }
  .kiwi-notconnected-button i {
    line-height: 36px;
  }
}
@media screen and (max-width: 769px) {
  .kiwi-notconnected {
    font-size: 0.8em;
    padding: 0 10px;
  }
  .kiwi-notconnected-caption {
    width: 100%;
    text-align: center;
    font-size: 1.3em;
  }
  .kiwi-notconnected-buttons {
    width: 100%;
  }
  .kiwi-notconnected-button {
    font-size: 1.2em;
    display: inline-block;
  }
}
