.kiwi-messagelist-message--compact {
  position: relative;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message-privmsg:hover,
.kiwi-messagelist-message--compact .kiwi-messagelist-message-action:hover,
.kiwi-messagelist-message--compact .kiwi-messagelist-message-notice:hover {
  cursor: pointer;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message--blur {
  opacity: 0.5;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-nick {
  width: 110px;
  min-width: 110px;
  display: inline-block;
  left: 8px;
  top: -1px;
  position: absolute;
  white-space: nowrap;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-nick:hover {
  width: auto;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-nick .kiwi-messagelist-time {
  margin-right: 10px;
  opacity: 0.8;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-time {
  display: inline-block;
  float: right;
  font-size: 12px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-body {
  display: block;
  white-space: pre-wrap;
  word-wrap: break-word;
  margin-left: 120px;
}
.kiwi-messagelist-message--compact .kiwi-messagelist-body a {
  word-break: break-all;
}
.kiwi-messagelist-message--compact .kiwi-messageinfo {
  padding-left: 130px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic {
  margin: 0;
  padding: 1px 0;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
  margin-left: 131px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-topic {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  margin: 1em 0;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message-topic .kiwi-messagelist-body {
  padding-right: 0;
  max-width: 95%;
  margin-left: 20px;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-time,
.kiwi-messagelist-message--compact.kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-message--compact.kiwi-messagelist-message--unread {
  opacity: 1;
}
@media screen and (max-width: 769px) {
  .kiwi-messagelist-message--compact {
    padding: 5px;
  }
  .kiwi-messagelist-message--compact .kiwi-messagelist-nick {
    display: inline;
    width: auto;
    min-width: auto;
    float: left;
    position: static;
    padding-left: 0;
  }
  .kiwi-messagelist-message--compact .kiwi-messagelist-time {
    text-align: right;
  }
  .kiwi-messagelist-message--compact .kiwi-messagelist-body {
    float: left;
    width: 100%;
    margin-left: 0;
    box-sizing: border-box;
  }
  .kiwi-messagelist-message--compact.kiwi-messagelist-message--unread .kiwi-messagelist-body {
    padding-left: 10px;
  }
  .kiwi-messagelist-message--compact .kiwi-messagelist-message--authorrepeat .kiwi-messagelist-nick {
    display: none;
  }
  .kiwi-messagelist-message--compact .kiwi-messageinfo {
    padding-left: 2px;
  }
  .kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
    margin-left: 0;
  }
  .kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic {
    margin-left: 10px;
  }
  .kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic.kiwi-messagelist-message--unread {
    margin-left: 0;
    padding-left: 10px;
  }
}
.kiwi-messagelist-message--compact .kiwi-messagelist-message-traffic .kiwi-messagelist-nick {
  display: none;
}
.kiwi-messagelist-item:last-of-type {
  margin-bottom: 5px;
}
@media screen and (min-width: 1000px) {
  .kiwi-messagelist-message--compact .kiwi-messagelist-nick {
    width: 160px;
    min-width: 160px;
  }
  .kiwi-messagelist-message--compact .kiwi-messagelist-nick:hover {
    width: auto;
  }
  .kiwi-messagelist-message--compact .kiwi-messagelist-body {
    margin-left: 170px;
  }
  .kiwi-messagelist-message--compact .kiwi-messageinfo {
    padding-left: 180px;
  }
  .kiwi-messagelist-message--compact.kiwi-messagelist-message-traffic .kiwi-messagelist-body {
    margin-left: 181px;
  }
}
