

















.kiwi-wrap-error {
    height: 100%;
    overflow: hidden;
    text-align: center;
}

.kiwi-wrap-error > div {
    margin-top: 0.5em;
    padding: 1em;
    font-size: 1.2em;
    border: 1px solid #b51111;
    background: #ffbaba;
    display: inline-block;
    text-align: left;
}

