


































































.kiwi-startup-common {
    height: 100%;
    text-align: center;
    display: flex;
}

.kiwi-startup-common-section {
    padding: 1em;
    box-sizing: border-box;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;

    /* transition the 2 sections out when the page closes. right+left defaults */
    transition: transform 0.4s;
}

.kiwi-startup-common-section-connection {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40%;
    max-width: 500px;
}

/** Right side */
.kiwi-startup-common-section-info {
    color: #fff;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
}

.kiwi-startup-common-section-info-content {
    background: rgba(255, 255, 255, 0.74);
    margin: 2em;
    color: #1b1b1b;
    font-size: 1.5em;
    padding: 2em;
    border-radius: 5px;
    line-height: 1.6em;
}

@media (min-width: 850px) {
    /** Closing - the wiping away of the screen **/
    .kiwi-startup-common--closing .kiwi-startup-common-section-connection {
        transform: translateX(-100%);
    }

    .kiwi-startup-common--closing .kiwi-startup-common-section-info {
        transform: translateX(100%);
    }
}

/** Smaller screen... **/
@media (max-width: 850px) {
    .kiwi-startup-common {
        font-size: 0.9em;
        display: block;
        overflow-y: auto;
    }

    .kiwi-startup-common-section {
        width: 100%;
        min-height: auto;
        max-width: none;
        height: auto;
        align-items: flex-start;
    }

    /** Closing - the wiping away of the screen **/
    .kiwi-startup-common--closing {
        transition: transform 0.3s;
        transform: translateY(100%);
    }

    .kiwi-startup-common-section-connection {
        padding-top: 2em;
    }

    .kiwi-startup-common-section-connection > * {
        max-width: 450px;
    }
}

/** Background / border switching between screen sizes **/
/* stylelint-disable  no-duplicate-selectors */
.kiwi-startup-common {
    background-size: 0;
    background-position: bottom;
}

.kiwi-startup-common-section-info {
    background-size: cover;
    background-position: bottom;
    border-left: 5px solid #42b992;
}

.kiwi-startup-common--no-bg .kiwi-startup-common-section-info {
    background-color: rgb(51, 51, 51);
}

/* stylint-enable */

@media (max-width: 850px) {
    /* Apply some flex so that the info panel fills the rest of the bottom screen */
    .kiwi-startup-common {
        background-size: cover;
    }

    .kiwi-startup-common-section-info {
        background-size: 0;
        border-left: none;
        flex: 1 0;
        display: block;
    }

    .kiwi-startup-common--no-bg .kiwi-startup-common-section-info {
        border-top: 5px solid #42b992;
    }
}

@media (max-width: 500px) {
    .kiwi-startup-common-section-info-content {
        margin: 1em;
    }
}
