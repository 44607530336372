



























































































































































































.kiwi-channellist {
    box-sizing: border-box;
    padding-bottom: 1em;
    text-align: center;
    transition: all 0.6s;
}

.kiwi-channellist-padding-top {
    padding-top: calc(45vh - 80px);
}

.kiwi-channellist-padding-top .kiwi-channellist-nav {
    width: 100%;
    text-align: center;
}

.kiwi-channellist-nav {
    padding: 10px 20px;
    box-sizing: border-box;
}

/* Input form styling */
.kiwi-channellist-nav .u-form {
    display: flex;
    justify-content: center;
}

.kiwi-channellist-nav .u-form .u-input {
    width: 324px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.kiwi-channellist-nav .u-form .u-button-primary,
.kiwi-channellist-nav .u-form .u-button-secondary {
    font-size: 1.3em;
    border-radius: 0 5px 5px 0;
    border: none;
    display: flex;
    align-items: center;
}

.kiwi-channellist-nav .u-form .u-button-primary i,
.kiwi-channellist-nav .u-form .u-button-secondary i {
    margin-left: -2px;
}

.kiwi-channellist-pagination {
    display: inline-block;
    margin: 20px auto 10px auto;
    font-size: 1.2em;
}

.kiwi-channellist-pagination a {
    display: inline-block;
    margin: 0 10px;
    cursor: pointer;
}

.kiwi-channellist-search {
    display: inline-block;
}

.kiwi-channellist-info {
    text-align: center;
}

/* Table Styling */

.kiwi-channellist-table {
    margin: 0 auto;
    width: 90%;
    max-width: 1800px;
    box-sizing: border-box;
}

.kiwi-channellist-grid {
    display: grid;
    grid-template-columns: 66px 130px auto min-content;
    border-bottom: 1px solid;
    align-items: center;
    text-align: left;
}

.kiwi-channellist-grid:first-of-type {
    border-top: 1px solid;
}

.kiwi-channellist-users {
    line-height: auto;
    margin-left: 0.2em;
    overflow: hidden;
    padding: 0.3em;
    text-align: left;
    white-space: nowrap;
}

.kiwi-channellist-users::before {
    font-family: fontAwesome, sans-serif;
    padding-right: 0.4em;
    content: "\f007";
}

.kiwi-channellist-name {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.kiwi-channellist-topic {
    display: inline-block;
    grid-column: 3;
    padding: 0.3em 0.5em;
}

.kiwi-channellist-topic:empty {
    display: none;
}

.kiwi-channellist-join {
    grid-column: 4;
    padding: 0.3em;
}

@media screen and (max-width: 1024px) {
    .kiwi-channellist-padding-top {
        padding-top: 100px;
    }
}

@media screen and (max-width: 770px) {
    .kiwi-channellist-nav .u-form .u-input {
        width: 100%;
    }

    /* Table Styling */
    .kiwi-channellist-table {
        width: 100%;
    }

    .kiwi-channellist-grid {
        grid-template-columns: 66px auto min-content;
    }

    .kiwi-channellist-topic {
        grid-column: 1 / span 3;
        grid-row: 2;
        word-break: break-word;
    }

    .kiwi-channellist-join {
        grid-column: 3;
    }
}

