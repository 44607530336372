.kiwi-inputtools-colours {
  bottom: 100%;
  right: 0;
  overflow: hidden;
  position: absolute;
  height: 30px;
}
.kiwi-inputtools-colours-colour {
  display: block;
  float: left;
  height: 30px;
  width: 30px;
  cursor: pointer;
  box-sizing: border-box;
  border: 1px solid gray;
  margin: 0;
}
.kiwi-inputtools-colours-reset {
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  background: #000;
  color: #cacaca;
}
