/* Adjust the sidebars width when this component is in view */
.kiwi-sidebar.kiwi-sidebar-section-about {
  max-width: 300px;
  width: 300px;
}
.kiwi-aboutbuffer {
  overflow-y: auto;
  box-sizing: border-box;
  min-height: 100px;
  margin: auto;
  width: 100%;
  padding-bottom: 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.kiwi-aboutbuffer-highlight {
  cursor: pointer;
}
.kiwi-aboutbuffer h3 {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
}
.kiwi-aboutbuffer-section {
  display: block;
  width: 100%;
}
.kiwi-aboutbuffer-section h4 {
  padding: 10px;
  cursor: pointer;
  user-select: none;
}
.kiwi-aboutbuffer-section h4 i {
  margin-right: 5px;
  transition: transform 0.2s;
}
.kiwi-aboutbuffer-section--closed h4 i {
  transform: rotate(90deg);
}
.kiwi-aboutbuffer-section > div {
  padding: 1em;
  transition: max-height 0.2s, padding 0.2s, opacity 0.2s;
  overflow: hidden;
}
.kiwi-aboutbuffer-section .kiwi-aboutbuffer-usercount {
  text-align: center;
}
.kiwi-aboutbuffer-section > div p {
  margin: 0 0 1em 0;
}
.kiwi-aboutbuffer-section > div p:last-of-type {
  margin-bottom: 0;
}
.kiwi-aboutbuffer-section--closed > div {
  max-height: 0;
  padding: 0;
  opacity: 0;
}
@media screen and (max-width: 769px) {
  .kiwi-sidebar.kiwi-sidebar-section-about {
    width: 100%;
    max-width: 100%;
  }
}
