





























































































.u-tabbed-view {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.u-tabbed-view-tabs {
    padding-top: 15px;
}

.u-tabbed-view-tab {
    display: inline-block;
    cursor: pointer;
    border-width: 0;
    border-style: solid;
    background: #fff;
    font-weight: 600;
    opacity: 1;
    z-index: 1;
    margin-bottom: -3px;
    position: relative;
    width: auto;
    text-align: left;
    box-sizing: border-box;
    padding: 0.5em 1em;
    border-bottom: 3px solid rgba(0, 0, 0, 0.1);
    transition: border 0.3s;
}

.u-tabbed-view-tab:hover,
.u-tabbed-view-tab--active {
    border-bottom-width: 3px;
}

.u-tabbed-view-tab:last-of-type {
    z-index: 1;
    border-radius: 0 4px 0 0;
}

.u-tabbed-content {
    overflow: auto;
    height: 100%;
}

@media screen and (max-width: 769px) {
    .u-tabbed-view-tabs {
        padding-top: 0;
    }

    .u-tabbed-view-tab {
        padding: 10px 20px;
        width: auto;
    }
}
