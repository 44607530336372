/* Adjust the sidebars width when this nicklist is in view */
.kiwi-container .kiwi-sidebar.kiwi-sidebar-section-nicklist {
  max-width: 250px;
  width: 250px;
}
.kiwi-nicklist {
  overflow: hidden;
  box-sizing: border-box;
  min-height: 100px;
  margin: auto;
  width: 100%;
  padding-bottom: 1px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.kiwi-nicklist-usercount {
  display: flex;
  justify-content: space-between;
  cursor: default;
  box-sizing: border-box;
  height: 43px;
  line-height: 40px;
  width: 100%;
  border-bottom: 1px solid;
}
.kiwi-nicklist-usercount span {
  margin-left: 15px;
  font-weight: 600;
}
.kiwi-nicklist-usercount .fa-search {
  opacity: 0.3;
  cursor: pointer;
  font-size: 1.2em;
  line-height: 40px;
  align-self: flex-start;
  margin-right: 15px;
}
.kiwi-nicklist-usercount .fa-search:hover,
.kiwi-nicklist--filtering .kiwi-nicklist-usercount .fa-search {
  opacity: 1;
}
.kiwi-nicklist-usercount input {
  width: 0%;
  border: none;
  font-weight: normal;
  background: none;
  outline: 0;
  padding: 0 15px 0 10px;
  opacity: 0;
  box-sizing: border-box;
  flex-grow: 1;
  transition: all 0.2s;
}
.kiwi-nicklist--filtering .kiwi-nicklist-usercount input {
  opacity: 1;
}
.kiwi-nicklist-users {
  width: 100%;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  max-height: 100%;
  flex: 1 auto;
  line-height: 1.2em;
  margin-top: 6px;
}
@media screen and (max-width: 759px) {
  .kiwi-container .kiwi-sidebar.kiwi-sidebar-section-nicklist {
    width: 100%;
    max-width: 380px;
  }
}
