





















































































/*
    try to force the browser into optimizing the animation
    using hardware acceleration
*/
* {
    will-change: height;
    transform: translateZ(0);
    backface-visibility: hidden;
    perspective: 1000px;
}
