

























































































.kiwi-loading-logo {
    display: none;
}

.kiwi-loading-animation {
    height: 100%;
    width: 100%;
}
