.kiwi-networksettings {
  box-sizing: border-box;
  height: 100%;
  margin: 0 auto;
  padding: 0;
}
.kiwi-networksettings .kiwi-title {
  width: 100%;
  line-height: 45px;
  height: 46px;
  padding: 0 10px;
  box-sizing: border-box;
  text-align: left;
  font-weight: 600;
  cursor: default;
}
.kiwi-networksettings .u-form {
  max-width: 400px;
  display: block;
  margin: 1em auto;
  padding: 0;
  height: auto;
  overflow: hidden;
  clear: both;
  border-radius: 2px;
  border: 1px solid;
}
.kiwi-networksettings .u-input-text {
  padding-top: 0;
  margin-bottom: 20px;
}
.kiwi-networksettings-section-block {
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
}
.kiwi-networksettings-captcha {
  margin-bottom: 1em;
}
.kiwi-networksettings .kiwi-customserver-tls {
  text-align: center;
  cursor: pointer;
  font-size: 1em;
}
.kiwi-networksettings .kiwi-customserver-tls i {
  left: 3px;
  top: 2px;
  font-size: 1.3em;
}
.kiwi-networksettings .kiwi-networksettings-server-types-info {
  width: 100%;
  text-align: left;
  margin-bottom: 10px;
}
.kiwi-networksettings .kiwi-networksettings-server-types {
  text-align: center;
}
.kiwi-networksettings .kiwi-networksettings-server-types .kiwi-network-type-button {
  margin: 0 10px 0 10px;
  display: inline-block;
  line-height: 35px;
  padding: 0 10px;
  border: 1px solid;
  transition: all 0.3s;
  border-radius: 4px;
}
.kiwi-networksettings .kiwi-show-advanced-title {
  text-align: center;
  cursor: pointer;
  padding-top: 0;
  margin: 40px 0 20px 0;
}
.kiwi-networksettings .kiwi-networksettings-advanced .kiwi-appsettings-showraw-label {
  margin-left: 5px;
}
.kiwi-networksettings .kiwi-connect-to-newnetwork {
  width: auto;
  margin: -10px auto 0 auto;
  border-radius: 3px;
  display: block;
  cursor: pointer;
  padding: 0 10px;
  line-height: 35px;
}
.kiwi-networksettings .kiwi-connect-to-newnetwork:hover {
  opacity: 1;
}
.kiwi-networksettings-advanced {
  margin-bottom: 20px;
}
.kiwi-dangerzone {
  text-align: center;
  padding: 10px 0 20px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.kiwi-dangerzone i {
  margin-right: 5px;
}
.kiwi-dangerzone h3 {
  padding-top: 0;
}
.kiwi-networksettings-error {
  text-align: center;
  margin: 1em 0 2em 0;
  padding: 1em;
  border: 1px dashed;
}
.kiwi-networksettings .kiwi-networksettings-error span {
  display: block;
  font-style: italic;
  text-align: center;
}
.kiwi-networksettings-server-types a {
  margin-right: 1em;
}
.kiwi-networksettings-server-types-info {
  font-size: 0.9em;
  font-style: italic;
}
.kiwi-networksettings-connection-address {
  width: 70%;
  display: inline-block;
}
.kiwi-networksettings-connection-port {
  width: 25%;
  float: right;
}
.kiwi-networksettings-danger h3 {
  padding: 0;
  margin-top: 0;
  margin-bottom: 0.5em;
}
.kiwi-networksettings-danger .u-button-warning {
  width: auto;
  height: 30px;
  border-radius: 4px;
  text-align: center;
  line-height: 30px;
  padding: 0 10px;
  opacity: 0.8;
  margin: 0 auto;
  transition: all 0.3s;
}
.kiwi-networksettings-danger .u-button-warning:hover {
  opacity: 1;
}
@media screen and (max-width: 769px) {
  .kiwi-networksettings {
    z-index: 100;
  }
}
